import * as tslib_1 from "tslib";
import { GenericService } from "src/app/core/services/generic.service";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
var QuestionarioFormandoService = /** @class */ (function (_super) {
    tslib_1.__extends(QuestionarioFormandoService, _super);
    function QuestionarioFormandoService(http, router) {
        var _this = _super.call(this, http, router) || this;
        _this.http = http;
        _this.router = router;
        _this.setEntityType("questionarioformando");
        return _this;
    }
    QuestionarioFormandoService.prototype.buscarPorPessoaId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/porpessoaid/" + id, { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.buscarPorPessoaIdEQuestionarioId = function (pessoaId, questionarioid) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/pessoa/" + pessoaId + "/questionario/" + questionarioid, { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.buscarPorCelulaId = function (busca) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/porcelulaid", JSON.stringify(busca), { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.buscarPorMembrosId = function (busca) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/pormembrosid/celula", JSON.stringify(busca), { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.buscarPorMembrosIdGenerico = function (busca) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/pormembrosid", JSON.stringify(busca), { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.buscarPorCasaComunitariaId = function (busca) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/porcasaid", JSON.stringify(busca), { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.buscarPorId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/" + id, { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.buscaPublicaPorId = function (id) {
        return this.http
            .get(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/pb/" + id)
            .toPromise();
    };
    QuestionarioFormandoService.prototype.cadastrar = function (request) {
        return this.http
            .post(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando", JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.atualizar = function (id, request) {
        return this.http
            .put(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/" + id, JSON.stringify(request), { headers: this.getHeaders() })
            .toPromise();
    };
    QuestionarioFormandoService.prototype.uploadQuestionario = function (questionarioFormandoId, pedidoFormandoId, file) {
        var formData = new FormData();
        formData.append("file", file);
        var headers = new HttpHeaders({
            Authorization: "Bearer " + (this.token ? this.token.access_token : ""),
        });
        return this.http.post(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/" + questionarioFormandoId + "/upload/" + pedidoFormandoId, formData, { headers: headers, responseType: "text" });
    };
    QuestionarioFormandoService.prototype.uploadCarta = function (questionarioFormandoId, file) {
        var formData = new FormData();
        formData.append("file", file);
        var headers = new HttpHeaders({
            Authorization: "Bearer " + (this.token ? this.token.access_token : ""),
        });
        return this.http.post(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/" + questionarioFormandoId + "/upload/carta", formData, { headers: headers, responseType: "text" });
    };
    QuestionarioFormandoService.prototype.removerArquivoQuestionario = function (questionarioFormandoId) {
        return this.http.delete(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/removerarquivo/questionario/" + questionarioFormandoId, { headers: this.getHeaders(), responseType: "text" });
    };
    QuestionarioFormandoService.prototype.removerArquivoCarta = function (questionarioFormandoId) {
        return this.http.delete(environment.moduloFormacao.urlModuloFormacao + "questionarioFormando/removerarquivo/carta/" + questionarioFormandoId, { headers: this.getHeaders(), responseType: "text" });
    };
    return QuestionarioFormandoService;
}(GenericService));
export { QuestionarioFormandoService };
