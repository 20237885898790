import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
var NovaSenhaEsqueceuSenhaComponent = /** @class */ (function () {
    function NovaSenhaEsqueceuSenhaComponent(esqueceuSenhaService, route, router, swtAlert2Service) {
        this.esqueceuSenhaService = esqueceuSenhaService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.showRequirements = false;
        this.newPass = {
            password: "",
            passwordConfirmation: "",
        };
    }
    NovaSenhaEsqueceuSenhaComponent.prototype.ngOnInit = function () {
        this.token = this.route.snapshot.paramMap.get("token");
    };
    NovaSenhaEsqueceuSenhaComponent.prototype.atualizarSenha = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var regexPassValidator, request, response, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.newPass.password.localeCompare(this.newPass.passwordConfirmation) !=
                            0) {
                            this.swtAlert2Service.warningAlert("As senhas informadas nos 2 não conincidem, reescreva a nova senha em ambos os campos para confirmar a atualização.");
                            return [2 /*return*/];
                        }
                        regexPassValidator = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,64}$/;
                        if (!regexPassValidator.test(this.newPass.password)) {
                            this.swtAlert2Service.warningAlert("Sua senha n\u00E3o cumpre todos os requisitos solicitados para uma senha forte.<br>\n         Veja os requisitos para a senha e atualize-a de modo a cumprir com todos os requisitos solicitados.\n        ");
                            this.showRequirements = true;
                            return [2 /*return*/];
                        }
                        request = {
                            token: this.token,
                            novaSenha: this.newPass.password,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.esqueceuSenhaService.atualizarSenha(request)];
                    case 2:
                        response = _a.sent();
                        this.swtAlert2Service.successAlert(response.message);
                        setTimeout(function () {
                            _this.router.navigate(["../login"]);
                        }, 5000);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return NovaSenhaEsqueceuSenhaComponent;
}());
export { NovaSenhaEsqueceuSenhaComponent };
