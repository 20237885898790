<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n><i class="fa fa-list"></i>&nbsp;&nbsp;&nbsp;Discernimentos</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12" style='text-align: center'>
                                    <table-component [cardBodyClass]="'table-responsive p-0'"
                                        [tableClass]="'table-striped'"
                                        [tituloSessao]="'Questionários disponíveis para informar parecer'" [cols]="15"
                                        [rows]="100" [totalDePaginas]="1" (eventoBotao)="cliqueNoBotaoListener($event)">
                                        <tr tableheader>
                                            <th>Foto de perfil</th>
                                            <th>Nome</th>
                                            <th>Nível Formativo</th>
                                            <th>Questionario</th>
                                            <th>Parecer</th>
                                            <th>Parecer FC</th>
                                            <th>Justificativa FC</th>
                                            <th>Parecer Comissão</th>
                                            <th>Justificativa Comissão</th>
                                            <th></th>
                                        </tr>
                                        <tr tablebody *ngFor="let membro of membrosCelula">
                                            <td>
                                                <div class="widget-user-image">
                                                    <img class="profile-user-img img-fluid img-circle image-size-list elevation-2"
                                                        [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail"
                                                        alt="User Avatar">
                                                </div>
                                            </td>
                                            <td>{{membro.pessoaNome}}</td>
                                            <td>{{membro.nivelFormativoNome}}</td>
                                            <td>
                                                <button *ngIf="membro.statusQuestionario == 'ENVIADO'"
                                                    class="btn btn-primary" (click)="carregarArquivoPDF(membro.arquivo)"
                                                    data-toggle="modal" data-target="#modal-view-questionario"
                                                    title="Questionario CAL" alt="Questionario CAL">Visualizar</button>
                                                <button *ngIf="membro.statusQuestionario != 'ENVIADO'"
                                                    class="btn btn-primary" disabled title="Questionario CAL"
                                                    alt="Questionario CAL">{{membro.statusQuestionario}}</button>
                                            <td>
                                                <button *ngIf="membro.statusParecerFC == 'ENVIADO'"
                                                    [routerLink]="['/secured/parecer-cal-answer-default-view/', membro.idParecerFC ]"
                                                    class="btn btn-primary" title="Parecer CAL"
                                                    alt="Parecer CAL">Visualizar</button>
                                                <button *ngIf="membro.statusParecerFC != 'ENVIADO'"
                                                    class="btn btn-primary" disabled title="Parecer CAL"
                                                    alt="Parecer CAL">{{membro.statusParecerFC}}</button>
                                            </td>
                                            <td>
                                                {{membro.indicacaoFCNome}}
                                            </td>
                                            <td [title]="membro.justificativaFC">
                                                {{ truncateText(membro.justificativaFC) }}
                                            </td>
                                            <td>
                                                <ng-container *ngIf="membro.editando">
                                                    <select class="form-control" id="nparecer_indicacaoCD"
                                                        [(ngModel)]="membro.indicacaoCD" name="nparecer_indicacaoCD">
                                                        <option disabled [value]="null" i18n>Selecione</option>
                                                        <!--option [value]="1"
                                                            *ngIf="membro.questionarioId == 6 || membro.questionarioId == 7 || membro.questionarioId == 8"
                                                            i18n>Mudança de Nível</option>
                                                        <option [value]="2"
                                                            *ngIf="membro.questionarioId == 4 || membro.questionarioId == 6 || membro.questionarioId == 7"
                                                            i18n>Permanecer no Nível</option>
                                                        <option [value]="3" *ngIf="membro.questionarioId == 4" i18n>
                                                            Realizar Primeiras Promessas</option>
                                                        <option [value]="4" *ngIf="membro.questionarioId == 5" i18n>
                                                            Realizar Promessas Definitivas</option>
                                                        <option [value]="5" i18n>Desligamento CAL</option>
                                                        <option [value]="6" i18n>Outro</option-->
                                                        <option value="3" *ngIf="membro.questionarioId == 4" i18n>Realizar
                                                            Primeiras Promessas</option>
                                                        <option value="4" *ngIf="membro.questionarioId == 5" i18n>Realizar
                                                            Promessas Definitivas</option>
                                                        <option [value]="7"
                                                            *ngIf="membro.questionarioId == 6"
                                                            i18n>Permanecer no P1</option>
                                                        <option [value]="8"
                                                            *ngIf="membro.questionarioId == 6"
                                                            i18n>Permanecer no P2</option>
                                                        <option [value]="9"
                                                            *ngIf="membro.questionarioId == 6"
                                                            i18n>Ingressar no P2</option>
                                                        <option [value]="10"
                                                            *ngIf="membro.questionarioId == 6"
                                                            i18n>Ingressar no D1</option>
                                                        <option [value]="11"
                                                            *ngIf="membro.questionarioId == 7"
                                                            i18n>Permanecer no D1</option>
                                                        <option [value]="12"
                                                            *ngIf="membro.questionarioId == 7"
                                                            i18n>Ingressar no D2</option>
                                                        <option [value]="13"
                                                            *ngIf="membro.questionarioId == 4"
                                                            i18n>Permanecer no D2</option>
                                                        <option [value]="14"
                                                            *ngIf="membro.questionarioId == 8"
                                                            i18n>Renovar as Promessas</option>
                                                        <option value="5" i18n>Desligamento CAL</option>
                                                    </select>
                                                </ng-container>
                                                <ng-container *ngIf="!membro.editando">
                                                    {{ membro.indicacaoCDNome}}
                                                </ng-container>
                                            </td>
                                            <ng-container *ngIf="membro.editando">
                                                <td>
                                                    <input type="text" class="form-control" maxlength="1500" id="justificativaCD"
                                                        name="justificativaCD"
                                                        [(ngModel)]="membro.justificativaCD" />&nbsp;
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="!membro.editando">
                                                <td [title]="membro.justificativaCD">
                                                    {{ truncateText(membro.justificativaCD) }}
                                                </td>
                                            </ng-container>
                                            <td>
                                                <button *ngIf="membro.editando == true" class="btn btn-sm btn-success"
                                                    (click)="cadastrarConsideracaoComissao(membro); membro.editando == false"
                                                    title="Atualizar parecer" alt="Atualizar parecer"><i
                                                        class="fas fa-save"></i></button>
                                                <button *ngIf="membro.editando != true" class="btn btn-sm btn-primary"
                                                    (click)="membro.editando = true" title="Editar parecer"
                                                    alt="Editar parecer"
                                                    [disabled]="(membro.statusParecerFC != 'ENVIADO')">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </table-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="modal-view-questionario" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Visualização do questionário</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <iframe *ngIf="sanitizedPdfUrl" [src]="sanitizedPdfUrl" width="100%" height="600px"
                                (contextmenu)="false;"></iframe>
                            <!-- <object [data]="sanitizedPdfUrl" type="application/pdf" width="100%" height="500px">
                                <p>Seu navegador não suporta exibição de PDFs.</p>
                            </object> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>