import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { UsuarioBuilder } from "src/app/builder/usuario.builder";
var UsuarioEditMembroComponent = /** @class */ (function () {
    function UsuarioEditMembroComponent(usuarioService, swtAlert2Service, route, router) {
        this.usuarioService = usuarioService;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.showRequirements = false;
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuario = this.usuarioBuilder.getInstance();
    }
    UsuarioEditMembroComponent.prototype.ngOnInit = function () {
        this.getUsuarioAtual();
    };
    UsuarioEditMembroComponent.prototype.getUsuarioAtual = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UsuarioEditMembroComponent.prototype.salvar = function (usuario) {
        var _this = this;
        var regexPassValidator = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,64}$/;
        if (usuario.password && !regexPassValidator.test(usuario.password)) {
            this.swtAlert2Service.warningAlert("Sua senha n\u00E3o cumpre todos os requisitos solicitados para uma senha forte.<br>\n       Veja os requisitos para a senha e atualize-a de modo a cumprir com todos os requisitos solicitados.\n      ");
            this.showRequirements = true;
            return;
        }
        this.usuarioService
            .update(usuario.id, usuario)
            .then(function (response) {
            _this.swtAlert2Service.successAlert(response.message);
        })
            .catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error);
        });
    };
    return UsuarioEditMembroComponent;
}());
export { UsuarioEditMembroComponent };
