import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { ParecerResponsavelBuilder } from "src/app/builder/parecerresponsavel.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { QuestionarioFormandoBuilder } from "src/app/builder/questionarioformando.builder";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { Celula } from "src/app/model/celula.model";
import { MembroComunidade } from "src/app/model/membro.comunidade.model";
import { ParecerResponsavel } from "src/app/model/parecerresponsavel.model";
import { Pessoa } from "src/app/model/pessoa.model";
import { QuestionarioFormando } from "src/app/model/questionarioformando.model";
import { Usuario } from "src/app/model/usuario.model";
import { ImagemService } from "../../service/imagem.service";
import { MembroComunidadeService } from "../../service/membro.comunidade.service";
import { CelulaService } from "../celula/service/celula.service";
import { PessoaService } from "../pessoa/service/pessoa.service";
import { QuestionarioFormandoService } from "../questionario/service/questionarioformando.service";
import { UsuarioService } from "../usuario/service/usuario.service";
import { ParecerResponsavelService } from "./service/parecerresponsavel.service";
import { ElementoGrupoDominioLocalService } from "../../service/elementogrupodominio.local.service";
import { CaminhoFormativoService } from "./service/caminhoformativo.service";
import {
  MissaoConfiguracaoService,
  QuestionarioConfiguracao,
} from "../questionario/service/configuracao-questionario.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ArquivoService } from "../../service/arquivo.service";

@Component({
  selector: "parecer-celula-comissao-discernimento",
  templateUrl: "./parecer.celula.comissao.component.html",
  styleUrls: [],
})
export class ParecerCelulaComissaoComponent implements OnInit {
  public usuario: Usuario;
  public pessoa: Pessoa;
  public celula: Celula;
  public membroComunidadeBuilder: MembroComunidadeBuilder;
  public membroComunidade: MembroComunidade;
  public membrosCelula: Array<any>;
  public pessoaBuilder: PessoaBuilder;

  public totalDePaginas: number = 1;

  public totalDePaginasMembros: number;
  public hasCelulaAssociada: boolean;

  public questionarioFormando: QuestionarioFormando;
  public parecerResponsavel: ParecerResponsavel;
  public celulaId: number;
  public questionariosMembros: Array<any>;

  public listaCorStatusVocacional!: Array<any>;

  questionarioConfiguracao: QuestionarioConfiguracao;

  public sanitizedPdfUrl: SafeResourceUrl;
  @ViewChild("pdfViewer", { static: false })
  pdfViewer: ElementRef;

  constructor(
    private missaoConfiguracaoService: MissaoConfiguracaoService,
    public usuarioService: UsuarioService,
    public imagemService: ImagemService,
    public pessoaService: PessoaService,
    public celulaService: CelulaService,
    public membroService: MembroComunidadeService,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelBuilder: ParecerResponsavelBuilder,
    public parecerResponsavelService: ParecerResponsavelService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public caminhoFormativoService: CaminhoFormativoService,
    private arquivoService: ArquivoService,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.pessoaBuilder = new PessoaBuilder();
    this.membrosCelula = new Array<any>();
    this.membroComunidadeBuilder = new MembroComunidadeBuilder();
    this.membroComunidade = this.membroComunidadeBuilder.getInstance();
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initializer();
    this.hasCelulaAssociada = false;
  }

  async initializer(): Promise<any> {
    await this.carregarUsuario();
    await this.carregarPessoa();
    this.celulaId = Number(this.route.snapshot.paramMap.get("id"));
    // await this.carregarCorStatusVocacional();
    await this.carregarMembrosCelula();
  }

  // async carregarCorStatusVocacional(): Promise<any> {
  //   try {
  //     this.listaCorStatusVocacional =
  //       await this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao(
  //         "COR_STATUS_VOCACIONAL"
  //       );
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  carregarQuestionarioConfiguracao(questionarioId: number) {
    const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
    this.missaoConfiguracaoService
      .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
        missaoId,
        questionarioId
      )
      .subscribe(
        (questionarioConfiguracao) =>
          (this.questionarioConfiguracao = questionarioConfiguracao)
      );
  }

  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarMembrosCelula(): Promise<any> {
    try {
      this.membrosCelula =
        await this.membroService.listarDetalhesPorCelulaIdFromView(
          this.celulaId
        );
      this.totalDePaginasMembros = 1;

      if (this.membrosCelula.length > 0) {
        this.membrosCelula = this.membrosCelula.sort((a, b) =>
          a.pessoaNome.localeCompare(b.pessoaNome)
        );
      }

      const idMembros = this.membrosCelula.map((membro) => membro.id);

      await this.carregarQuestionarioMembro(idMembros);
      console.log(this.questionariosMembros);

      for (let i = 0; i < this.membrosCelula.length; i++) {
        let response = await this.pessoaService.buscaPorId(
          this.membrosCelula[i].pessoaId
        );
        let pessoa = response.entity;
        this.membrosCelula[i].pessoaImagemId = pessoa.imagemId;
        if (this.membrosCelula[i].pessoaImagemId != null) {
          let blogImage = await this.getPessoaImagem(
            this.membrosCelula[i].pessoaImagemId
          );
          this.imagemService.createImage(blogImage, this.membrosCelula[i]);
        }

        this.membrosCelula[i].editando = false;

        const questionario = this.questionariosMembros.filter(
          (questionarioFormando) =>
            questionarioFormando.id === this.membrosCelula[i].id
        );

        if (questionario.length > 0) {
          this.membrosCelula[i].statusQuestionario =
            questionario[0].status_questionario;
          this.membrosCelula[i].idQuestionarioFormando =
            questionario[0].questionarioFormandoId;
          this.membrosCelula[i].questionarioId = questionario[0].questionarioId;
          this.membrosCelula[i].statusParecerFC =
            questionario[0].status_parecer_fc;
          this.membrosCelula[i].idParecerFC = questionario[0].parecerIdFC;
          this.membrosCelula[i].acompanhadoPor = questionario[0].acompanhadoPor;
          this.membrosCelula[i].acompanhadoPorNome =
            questionario[0].acompanhadoPorNome;
          this.membrosCelula[i].arquivo = questionario[0].arquivo;
          this.membrosCelula[i].dataEnvioParecerFC =
            questionario[0].dataEnvioParecerFC;
          this.membrosCelula[i].dataEnvioQuestionario =
            questionario[0].dataEnvioQuestionario;
          this.membrosCelula[i].missaoAtualId =
            questionario[0].pessoamissaoAtualId;
          this.membrosCelula[i].questionarioNome = this.buscarNomeQuestionario(
            this.questionarioFormando.questionarioId
          );
          //this.membrosCelula[i].pedidoFormando = this.buscarNomePedido(questionario[0].indicacaoFC);
          this.membrosCelula[i].indicacaoFCNome = this.buscarNomePedido(
            questionario[0].indicacaoFC
          );
          this.membrosCelula[i].justificativaFC =
            questionario[0].justificativaFC;
          this.membrosCelula[i].indicacaoCD = questionario[0].indicacaoCD;
          this.membrosCelula[i].indicacaoCDNome = this.buscarNomePedido(
            questionario[0].indicacaoCD
          );
          this.membrosCelula[i].justificativaCD =
            questionario[0].justificativaCD;
        }
        if (
          this.membrosCelula[i].statusQuestionario == "NAOINICIADO" ||
          this.membrosCelula[i].statusQuestionario == undefined ||
          this.membrosCelula[i].statusQuestionario == null
        ) {
          this.membrosCelula[i].statusQuestionario = "Não Iniciado";
        }
        if (
          this.membrosCelula[i].statusParecerFC == "NAOINICIADO" ||
          this.membrosCelula[i].statusParecerFC == undefined ||
          this.membrosCelula[i].statusParecerFC == null
        ) {
          this.membrosCelula[i].statusParecerFC = "Não Iniciado";
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  buscarNomePedido(idPedido: number): string {
    let nomePedido = "";
    if (idPedido) {
      switch (idPedido) {
        case 1:
          nomePedido = "Mudança de Nível";
          break;
        case 2:
          nomePedido = "Permanecer no Nível";
          break;
        case 3:
          nomePedido = "Realizar Primeiras Promessas";
          break;
        case 4:
          nomePedido = "Realizar Promessas Definitivas";
          break;
        case 5:
          nomePedido = "Desligamento CAL";
          break;
        case 6:
          nomePedido = "Outro";
          break;
        case 7:
          nomePedido = "Permanecer no P1<";
          break;
        case 8:
          nomePedido = "Permanecer no P2";
          break;
        case 9:
          nomePedido = "Ingressar no P2";
          break;
        case 10:
          nomePedido = "Ingressar no D1";
          break;
        case 11:
          nomePedido = "Permanecer no D1";
          break;
        case 12:
          nomePedido = "Ingressar no D2";
          break;
        case 13:
          nomePedido = "Permanecer no D2";
          break;
        case 14:
          nomePedido = "Renovar as Promessas";
          break;      
        default:
          nomePedido = null;
          break;
      }
    }
    return nomePedido;
  }

  buscarNomeQuestionario(idQuestionario: number): string {
    let nomeQuestionario = "";
    if (idQuestionario) {
      switch (idQuestionario) {
        case 4:
          nomeQuestionario = "Pedido para as Primeiras Promessas - CA";
          break;
        case 5:
          nomeQuestionario = "Pedido para as Promessas Definitivas - CA";
          break;
        case 6:
          nomeQuestionario = "Postulantes/Ingresso no Discipulado - CA";
          break;
        case 7:
          nomeQuestionario = "Discipulos de Primeiro Ano - CA";
          break;
        case 8:
          nomeQuestionario = "Renovação das Promessas - CA";
          break;
        default:
          nomeQuestionario = null;
          break;
      }
    }
    return nomeQuestionario;
  }

  async carregarQuestionarioMembro(idMembros: any): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorMembrosId(
        idMembros
      );
      this.questionariosMembros = response;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarArquivoPDF(arquivoPDFNome: string): Promise<any> {
    const blobResponse = await this.arquivoService
      .obterDetalhesArquivoPDF(arquivoPDFNome)
      .toPromise();

    const url = URL.createObjectURL(blobResponse);
    this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async cadastrarConsideracaoComissao(membro): Promise<any> {
    try {
      await this.atualizarQuestionarioFormando(membro);
      await this.carregarMembrosCelula();
      this.swtAlert2Service.successAlert(
        "Parecer de consideração da Comissão de Discernimento atualizado com sucesso!"
      );
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarQuestionarioFormando(membro: any): Promise<any> {
    try {
      let responseQuestionario =
        await this.questionarioFormandoService.buscarPorId(
          membro.idQuestionarioFormando
        );
      this.questionarioFormando = responseQuestionario;
      if (this.questionarioFormando.id != null) {
        this.questionarioFormando.indicacaoCD = membro.indicacaoCD;
        this.questionarioFormando.justificativaCD = membro.justificativaCD;
        let response = await this.questionarioFormandoService.atualizar(
          this.questionarioFormando.id,
          this.questionarioFormando
        );
        this.questionarioFormando = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  cliqueNoBotaoListener(botao) {
    this.carregarMembrosCelula();
  }

  truncateText(text: string | null | undefined, limit: number = 50): string {
    if (!text) {
      return '';
    }
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  }
}
