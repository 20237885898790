import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
var ParecerCoordenadorColegiadoCelulaComponent = /** @class */ (function () {
    function ParecerCoordenadorColegiadoCelulaComponent(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, celulaService, membroService, coordenadorColegiadoService, formadorComunitarioService, formadorAssistenteService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, caminhoFormativoService, discernimentoService, arquivoService, sanitizer, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.coordenadorColegiadoService = coordenadorColegiadoService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.formadorAssistenteService = formadorAssistenteService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.discernimentoService = discernimentoService;
        this.arquivoService = arquivoService;
        this.sanitizer = sanitizer;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.unsubscribe$ = new Subject();
        this.totalDePaginas = 1;
        this.selectedFile = null;
        this.errorMessage = "";
        this.isFormValidUpload = false;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ParecerCoordenadorColegiadoCelulaComponent.prototype.ngOnInit = function () {
        this.initializer();
        this.hasCelulaAssociada = false;
        this.paginacaoConfig = {
            page: 0,
            size: 10,
            totalPages: 0,
            totalElements: 0,
        };
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarFormadoresCelula()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarQuestionarioConfiguracao = function (questionarioId) {
        var _this = this;
        var missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, questionarioId)
            .subscribe(function (questionarioConfiguracao) {
            return (_this.questionarioConfiguracao = questionarioConfiguracao);
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarFormadoresCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, idMembros, _loop_1, this_1, i, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        return [4 /*yield*/, this.discernimentoService.obterMembrosParaDiscernimentoDoCoordenadorDeColegiado(this.paginacaoConfig.page, this.paginacaoConfig.size)];
                    case 1:
                        response = _a.sent();
                        this.formadoresDaMissao = response.content;
                        this.paginacaoConfig.totalPages = response.totalPages;
                        this.paginacaoConfig.totalElements = response.totalElements;
                        idMembros = this.formadoresDaMissao.map(function (membro) { return membro.id; });
                        return [4 /*yield*/, this.carregarQuestionarioColegiado(idMembros)];
                    case 2:
                        _a.sent();
                        if (this.formadoresDaMissao.length > 0) {
                            this.formadoresDaMissao = this.formadoresDaMissao.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        _loop_1 = function (i) {
                            var blogImage, questionario;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(this_1.formadoresDaMissao[i].imagemId != null)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this_1.getPessoaImagem(this_1.formadoresDaMissao[i].imagemId)];
                                    case 1:
                                        blogImage = _a.sent();
                                        this_1.imagemService.createImage(blogImage, this_1.formadoresDaMissao[i]);
                                        _a.label = 2;
                                    case 2:
                                        questionario = this_1.questionariosColegiado.filter(function (questionarioFormando) {
                                            return questionarioFormando.id === _this.formadoresDaMissao[i].id;
                                        });
                                        if (questionario.length > 0) {
                                            this_1.formadoresDaMissao[i].statusQuestionario =
                                                questionario[0].status_questionario;
                                            this_1.formadoresDaMissao[i].idQuestionarioFormando =
                                                questionario[0].questionarioFormandoId;
                                            this_1.formadoresDaMissao[i].questionarioId = questionario[0].questionarioId;
                                            this_1.formadoresDaMissao[i].questionarioNome = this_1.buscarNomeQuestionario(this_1.formadoresDaMissao[i].questionarioId);
                                            this_1.formadoresDaMissao[i].statusParecerFC =
                                                questionario[0].status_parecer_fc;
                                            this_1.formadoresDaMissao[i].idParecerFC = questionario[0].parecerIdFC;
                                            this_1.formadoresDaMissao[i].acompanhadoPor = questionario[0].acompanhadoPor;
                                            this_1.formadoresDaMissao[i].acompanhadoPorNome =
                                                questionario[0].acompanhadoPorNome;
                                            this_1.formadoresDaMissao[i].arquivo = questionario[0].arquivo;
                                            this_1.formadoresDaMissao[i].dataEnvioParecerFC =
                                                questionario[0].dataEnvioParecerFC;
                                            this_1.formadoresDaMissao[i].dataEnvioQuestionario =
                                                questionario[0].dataEnvioQuestionario;
                                            this_1.formadoresDaMissao[i].missaoAtualId = questionario[0].pessoamissaoAtualId;
                                            this_1.formadoresDaMissao[i].carta = questionario[0].carta;
                                            if (this_1.formadoresDaMissao[i].statusQuestionario == "NAOINICIADO") {
                                                this_1.formadoresDaMissao[i].statusQuestionario =
                                                    "Não Iniciado";
                                            }
                                            if (this_1.formadoresDaMissao[i].statusParecerFC == "NAOINICIADO") {
                                                this_1.formadoresDaMissao[i].statusParecerFC = "Não Iniciado";
                                            }
                                        }
                                        else {
                                            this_1.formadoresDaMissao[i].statusQuestionario = null;
                                            this_1.formadoresDaMissao[i].idQuestionarioFormando = null;
                                            this_1.formadoresDaMissao[i].questionarioId = null;
                                            this_1.formadoresDaMissao[i].statusParecerFC = null;
                                            this_1.formadoresDaMissao[i].idParecerFC = null;
                                            this_1.formadoresDaMissao[i].arquivo = null;
                                            this_1.formadoresDaMissao[i].dataEnvioParecerFC = null;
                                            this_1.formadoresDaMissao[i].dataEnvioQuestionario = null;
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        i = 0;
                        _a.label = 3;
                    case 3:
                        if (!(i < this.formadoresDaMissao.length)) return [3 /*break*/, 6];
                        return [5 /*yield**/, _loop_1(i)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        i++;
                        return [3 /*break*/, 3];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarQuestionarioColegiado = function (idMembros) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorMembrosId(idMembros)];
                    case 1:
                        response = _a.sent();
                        this.questionariosColegiado = response;
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        console.log(e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.consultarCaminhoFormativo = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response_1, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.caminhoFormativoService.porPessoaId(formando.pessoaId)];
                    case 1:
                        response = _a.sent();
                        if (!(response != null && response != undefined)) return [3 /*break*/, 2];
                        this.caminhoFormativo = response;
                        return [3 /*break*/, 4];
                    case 2:
                        this.caminhoFormativo = {};
                        this.caminhoFormativo.pessoaId = formando.pessoaId;
                        this.caminhoFormativo.missao_atual_id = formando.missaoAtualId;
                        this.caminhoFormativo.ano_formativo_id = 2;
                        this.caminhoFormativo.nome_formador_comunitario =
                            this.pessoa.nome + ' ' + this.pessoa.sobrenome;
                        return [4 /*yield*/, this.caminhoFormativoService.cadastrar(this.caminhoFormativo)];
                    case 3:
                        response_1 = _a.sent();
                        this.caminhoFormativo = response_1;
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.cadastrarVinculoQuestionarioCAL = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var responsePorPessoa, response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.consultarCaminhoFormativo(formando)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorPessoaIdEQuestionarioId(formando.pessoaId, formando.questionarioId)];
                    case 2:
                        responsePorPessoa = _a.sent();
                        if (!(responsePorPessoa != undefined &&
                            responsePorPessoa != null &&
                            responsePorPessoa.questionarioId == formando.questionarioId)) return [3 /*break*/, 3];
                        this.questionarioFormando = responsePorPessoa;
                        this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                        return [3 /*break*/, 5];
                    case 3:
                        this.questionarioFormando.anoFormativoId = 2;
                        this.questionarioFormando.questionarioId = formando.questionarioId;
                        this.questionarioFormando.pessoaId = formando.pessoaId;
                        return [4 /*yield*/, this.questionarioFormandoService.cadastrar(this.questionarioFormando)];
                    case 4:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.questionarioFormando = response;
                            this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                            formando.idQuestionarioFormando = this.questionarioFormando.id;
                        }
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.cadastrarVinculoParecerCAL = function (questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                        this.parecerResponsavel.parecer_id = 3;
                        this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
                        this.parecerResponsavel.tipo = "FC";
                        return [4 /*yield*/, this.parecerResponsavelService.cadastrar(this.parecerResponsavel)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.parecerResponsavel = response;
                            this.router.navigate([
                                "/secured/parecer-cal-answer-default" +
                                    "/" +
                                    this.parecerResponsavel.id,
                            ]);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.buscarNomeQuestionario = function (idQuestionario) {
        var nomeQuestionario = "";
        if (idQuestionario) {
            switch (idQuestionario) {
                case 4:
                    nomeQuestionario = "Pedido para as Primeiras Promessas - CA";
                    break;
                case 5:
                    nomeQuestionario = "Pedido para as Promessas Definitivas - CA";
                    break;
                case 6:
                    nomeQuestionario = "Postulantes/Ingresso no Discipulado - CA";
                    break;
                case 7:
                    nomeQuestionario = "Discipulos de Primeiro Ano - CA";
                    break;
                case 8:
                    nomeQuestionario = "Renovação das Promessas - CA";
                    break;
                default:
                    nomeQuestionario = null;
                    break;
            }
        }
        return nomeQuestionario;
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.consultarVinculoQuestionarioCAL = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(formando.idQuestionarioFormando != undefined &&
                            formando.idQuestionarioFormando != null)) return [3 /*break*/, 1];
                        if (formando.idParecerFC != undefined && formando.idParecerFC != null) {
                            this.router.navigate([
                                "/secured/parecer-cal-answer-default" + "/" + formando.idParecerFC,
                            ]);
                        }
                        else {
                            this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando);
                        }
                        return [3 /*break*/, 4];
                    case 1:
                        if (!(formando.idQuestionarioFormando == undefined ||
                            formando.idQuestionarioFormando == null)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.cadastrarVinculoQuestionarioCAL(formando)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarArquivoPDF = function (arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blobResponse, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.arquivoService
                            .obterDetalhesArquivoPDF(arquivoPDFNome)
                            .toPromise()];
                    case 1:
                        blobResponse = _a.sent();
                        url = URL.createObjectURL(blobResponse);
                        this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.configModalUploadQuestionario = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(formando.questionarioFormandoId != undefined &&
                            formando.questionarioFormandoId != null)) return [3 /*break*/, 1];
                        this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
                        this.questionarioSelectedId = formando.questionarioId;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.cadastrarVinculoQuestionarioCAL(formando)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.onFileSelected = function (event) {
        var file = event.target.files[0];
        // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
        if (file &&
            file.type === "application/pdf" &&
            file.size <= 3 * 1024 * 1024) {
            this.selectedFile = file;
            this.errorMessage = "";
        }
        else {
            this.selectedFile = null;
            this.errorMessage = "O arquivo deve ser um PDF com no máximo 3 MB.";
        }
        this.checkFormUploadValidity();
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.onUpload = function () {
        var _this = this;
        if (this.selectedFile && this.pedidoFormandoId) {
            this.questionarioFormandoService
                .uploadQuestionario(this.questionarioFormandoSelectedId, this.pedidoFormandoId, this.selectedFile)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                next: function (data) {
                    _this.swtAlert2Service.successAlert(data);
                    _this.carregarFormadoresCelula();
                    $("#modal-upload-questionario").modal("hide");
                },
                error: function (error) {
                    _this.swtAlert2Service.errorAlert("Erro ao realizar o upload do arquivo.");
                },
                complete: function () {
                    _this.unsubscribe$.next();
                },
            });
            this.pedidoFormandoId = null;
        }
        else {
            if (!this.pedidoFormandoId) {
                this.errorMessage =
                    "O campo 'Qual Pedido o seu do Formando está fazendo' deve ser informado.";
            }
            else {
                this.errorMessage =
                    "Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb";
            }
        }
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.onTipoPedidoChange = function () {
        this.checkFormUploadValidity();
    };
    // Verifica se tanto o arquivo quanto o tipo de pedido foram selecionados
    ParecerCoordenadorColegiadoCelulaComponent.prototype.checkFormUploadValidity = function () {
        this.isFormValidUpload = !!this.pedidoFormandoId && !!this.selectedFile;
    };
    /**
     * CARTA
     */
    ParecerCoordenadorColegiadoCelulaComponent.prototype.configModalUploadCarta = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(formando.questionarioFormandoId != undefined &&
                            formando.questionarioFormandoId != null)) return [3 /*break*/, 1];
                        this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.cadastrarVinculoQuestionarioCAL(formando)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.carregarCartaPDF = function (arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blobResponse, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.arquivoService
                            .obterDetalhesArquivoPDF(arquivoPDFNome)
                            .toPromise()];
                    case 1:
                        blobResponse = _a.sent();
                        url = URL.createObjectURL(blobResponse);
                        this.sanitizedPdfCartaUrl =
                            this.sanitizer.bypassSecurityTrustResourceUrl(url);
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.onCartaFileSelected = function (event) {
        var file = event.target.files[0];
        // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
        if (file &&
            file.type === "application/pdf" &&
            file.size <= 3 * 1024 * 1024) {
            this.selectedFile = file;
        }
        else {
            this.selectedFile = null;
        }
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.onCartaUpload = function () {
        var _this = this;
        if (this.selectedFile) {
            this.questionarioFormandoService
                .uploadCarta(this.questionarioFormandoSelectedId, this.selectedFile)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                next: function (data) {
                    _this.swtAlert2Service.successAlert(data);
                    _this.carregarFormadoresCelula();
                    $("#modal-upload-carta").modal("hide");
                },
                error: function (error) {
                    _this.swtAlert2Service.errorAlert("Erro ao realizar o upload do arquivo.");
                },
                complete: function () {
                    _this.unsubscribe$.next();
                },
            });
        }
    };
    ParecerCoordenadorColegiadoCelulaComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.paginacaoConfig.page = botao.numero - 1;
        this.carregarFormadoresCelula();
    };
    return ParecerCoordenadorColegiadoCelulaComponent;
}());
export { ParecerCoordenadorColegiadoCelulaComponent };
