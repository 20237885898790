import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
var CasasComunitariasListarComponent = /** @class */ (function () {
    function CasasComunitariasListarComponent(regionalService, missaoService, casaComunitariaService, elementoGrupoDominioService, swtAlert2Service) {
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.casaComunitariaService = casaComunitariaService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.swtAlert2Service = swtAlert2Service;
        this.regionais = new Array();
        this.missoes = new Array();
        this.casasComunitaria = new Array();
        this.statusCasaComunitaria = new Array();
        this.busca = {
            nome: null,
            ano: null,
            regionalId: null,
            missaoId: null,
            paisId: null,
            estadoId: null,
            cidadeId: null,
            statusId: null,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    CasasComunitariasListarComponent.prototype.ngOnInit = function () {
        this.carregarRegionais();
        this.carregarStatusCasaComunitaria();
        this.buscarCasasComunitaria();
    };
    CasasComunitariasListarComponent.prototype.ngOnDestroy = function () {
    };
    CasasComunitariasListarComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarCasasComunitaria();
    };
    CasasComunitariasListarComponent.prototype.carregarStatusCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        request = {
                            grupoNome: 'CASA_COMUNITARIA_STATUS'
                        };
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request)];
                    case 1:
                        response = _a.sent();
                        this.statusCasaComunitaria = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, null];
                }
            });
        });
    };
    CasasComunitariasListarComponent.prototype.carregarRegionais = function () {
        var _this = this;
        this.regionalService.findAll().then(function (lista) {
            _this.regionais = lista.entity;
        }).catch(function (err) {
            console.log(err);
        });
    };
    CasasComunitariasListarComponent.prototype.carregarMissoes = function () {
        var _this = this;
        this.missaoService.porRegional(this.busca.regionalId).then(function (response) {
            _this.missoes = response.entity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    CasasComunitariasListarComponent.prototype.buscarCasasComunitaria = function () {
        var _this = this;
        this.casaComunitariaService.buscarCasasComunitaria(this.busca).then(function (response) {
            _this.casasComunitaria = response.entity;
            _this.totalDePaginas = response.quantity;
        }).catch(function (err) {
            _this.swtAlert2Service.errorAlert(err.error.errors);
        });
    };
    CasasComunitariasListarComponent.prototype.deletarCasaComunitaria = function (id) {
        var _this = this;
        if (confirm('Deseja deletar esta casa comunitaria?')) {
            this.casaComunitariaService.delete(id)
                .then(function (response) {
                _this.swtAlert2Service.successAlert(response.message);
                _this.buscarCasasComunitaria();
            })
                .catch(function (err) {
                _this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    };
    return CasasComunitariasListarComponent;
}());
export { CasasComunitariasListarComponent };
