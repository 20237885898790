import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CasaComunitariaLocalManageComponent } from './casacomunitaria.manager.component';
import { CasasComunitariasListarComponent } from './casascomunitarias.listar.component';

const casacomunitariaRoutes: Routes = [
    {
        path: 'secured/local/casacomunitaria/gerenciar/:id',
        component: CasaComunitariaLocalManageComponent
    },
    {
        path: 'secured/local/casacomunitaria/gerenciamento',
        component: CasaComunitariaLocalManageComponent
    },
    {
        path: 'secured/cc/casacomunitaria',
        component: CasasComunitariasListarComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(casacomunitariaRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CasaComunitariaLocalRoutingModule {
    
}