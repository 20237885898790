import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { RegionalService } from '../../service/regional.service';
import { MissaoService } from '../../service/missao.service';
import { CasaComunitariaService } from './service/casacomunitaria.service';
import { ElementoGrupoDominioService } from '../../service/elementogrupodominio.service';

@Component({
  selector: 'search-celula-af',
  templateUrl: './casascomunitarias.listar.component.html',
})
export class CasasComunitariasListarComponent
  implements OnInit, OnDestroy
{

  public regionais: Array<Regional>;
  public missoes: Array<Missao>;
  public casasComunitaria: Array<CasaComunitaria>;
  public statusCasaComunitaria: Array<ElementoGrupoDominio>;
  public busca: any;
  public totalDePaginas: number;

  constructor(
      public regionalService: RegionalService,
      public missaoService: MissaoService,
      public casaComunitariaService: CasaComunitariaService,
      public elementoGrupoDominioService: ElementoGrupoDominioService,
      public swtAlert2Service: SwtAlert2Service
  ) 
  {
      this.regionais = new Array<Regional>();
      this.missoes = new Array<Missao>();
      this.casasComunitaria = new Array<CasaComunitaria>();   
      this.statusCasaComunitaria = new Array<ElementoGrupoDominio>();        
      this.busca = {            
          nome: null,
          ano: null,
          regionalId: null,
          missaoId: null,
          paisId: null,
          estadoId: null,
          cidadeId: null,
          statusId: null,
          pageNumber : 0, 
          quantityOfElements : 10
      };
  }

  ngOnInit() {
      this.carregarRegionais(); 
      this.carregarStatusCasaComunitaria();
      this.buscarCasasComunitaria();
  }   

  ngOnDestroy(): void {
  }

  cliqueNoBotaoListener(botao) {
      this.busca.pageNumber = botao.numero-1;
      this.buscarCasasComunitaria();
  }

  async carregarStatusCasaComunitaria() : Promise<null> {

      try{
          let request = {
              grupoNome : 'CASA_COMUNITARIA_STATUS'
          }
          let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
          this.statusCasaComunitaria = response.entity;

      }
      catch(err){
          this.swtAlert2Service.errorAlert(err.error.errors);
      }

      return null;
  }

  carregarRegionais() : void {
      this.regionalService.findAll().then( (lista: any) => { 
          this.regionais = lista.entity;
      }).catch( (err: any) => {
          console.log(err);
      });
  }

  carregarMissoes(): void {
      this.missaoService.porRegional(this.busca.regionalId).then((response: any) => {
          this.missoes = response.entity;          
      }).catch((err: any) => {
          this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  buscarCasasComunitaria() : void {
      this.casaComunitariaService.buscarCasasComunitaria(this.busca).then( (response: any) => {
          this.casasComunitaria = response.entity;
          this.totalDePaginas = response.quantity;
      }).catch( (err: any) => {
          this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }
  
  deletarCasaComunitaria(id: number) : void {

      if(confirm('Deseja deletar esta casa comunitaria?'))
      {
          this.casaComunitariaService.delete(id)
          .then( (response: any) => {
              this.swtAlert2Service.successAlert(response.message);
              this.buscarCasasComunitaria();
          })
          .catch((err) => {
              this.swtAlert2Service.errorAlert(err.error.errors);
          });
      }
  }
}
