import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
var ParecerCelulaComissaoComponent = /** @class */ (function () {
    function ParecerCelulaComissaoComponent(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, celulaService, membroService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, elementoGrupoDominioLocalService, caminhoFormativoService, arquivoService, sanitizer, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.arquivoService = arquivoService;
        this.sanitizer = sanitizer;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ParecerCelulaComissaoComponent.prototype.ngOnInit = function () {
        this.initializer();
        this.hasCelulaAssociada = false;
    };
    ParecerCelulaComissaoComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        this.celulaId = Number(this.route.snapshot.paramMap.get("id"));
                        // await this.carregarCorStatusVocacional();
                        return [4 /*yield*/, this.carregarMembrosCelula()];
                    case 3:
                        // await this.carregarCorStatusVocacional();
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // async carregarCorStatusVocacional(): Promise<any> {
    //   try {
    //     this.listaCorStatusVocacional =
    //       await this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao(
    //         "COR_STATUS_VOCACIONAL"
    //       );
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
    ParecerCelulaComissaoComponent.prototype.carregarQuestionarioConfiguracao = function (questionarioId) {
        var _this = this;
        var missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, questionarioId)
            .subscribe(function (questionarioConfiguracao) {
            return (_this.questionarioConfiguracao = questionarioConfiguracao);
        });
    };
    ParecerCelulaComissaoComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComissaoComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComissaoComponent.prototype.carregarMembrosCelula = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, idMembros, _loop_1, this_1, i, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 7, , 8]);
                        _a = this;
                        return [4 /*yield*/, this.membroService.listarDetalhesPorCelulaIdFromView(this.celulaId)];
                    case 1:
                        _a.membrosCelula =
                            _b.sent();
                        this.totalDePaginasMembros = 1;
                        if (this.membrosCelula.length > 0) {
                            this.membrosCelula = this.membrosCelula.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        idMembros = this.membrosCelula.map(function (membro) { return membro.id; });
                        return [4 /*yield*/, this.carregarQuestionarioMembro(idMembros)];
                    case 2:
                        _b.sent();
                        console.log(this.questionariosMembros);
                        _loop_1 = function (i) {
                            var response, pessoa, blogImage, questionario;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this_1.pessoaService.buscaPorId(this_1.membrosCelula[i].pessoaId)];
                                    case 1:
                                        response = _a.sent();
                                        pessoa = response.entity;
                                        this_1.membrosCelula[i].pessoaImagemId = pessoa.imagemId;
                                        if (!(this_1.membrosCelula[i].pessoaImagemId != null)) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this_1.getPessoaImagem(this_1.membrosCelula[i].pessoaImagemId)];
                                    case 2:
                                        blogImage = _a.sent();
                                        this_1.imagemService.createImage(blogImage, this_1.membrosCelula[i]);
                                        _a.label = 3;
                                    case 3:
                                        this_1.membrosCelula[i].editando = false;
                                        questionario = this_1.questionariosMembros.filter(function (questionarioFormando) {
                                            return questionarioFormando.id === _this.membrosCelula[i].id;
                                        });
                                        if (questionario.length > 0) {
                                            this_1.membrosCelula[i].statusQuestionario =
                                                questionario[0].status_questionario;
                                            this_1.membrosCelula[i].idQuestionarioFormando =
                                                questionario[0].questionarioFormandoId;
                                            this_1.membrosCelula[i].questionarioId = questionario[0].questionarioId;
                                            this_1.membrosCelula[i].statusParecerFC =
                                                questionario[0].status_parecer_fc;
                                            this_1.membrosCelula[i].idParecerFC = questionario[0].parecerIdFC;
                                            this_1.membrosCelula[i].acompanhadoPor = questionario[0].acompanhadoPor;
                                            this_1.membrosCelula[i].acompanhadoPorNome =
                                                questionario[0].acompanhadoPorNome;
                                            this_1.membrosCelula[i].arquivo = questionario[0].arquivo;
                                            this_1.membrosCelula[i].dataEnvioParecerFC =
                                                questionario[0].dataEnvioParecerFC;
                                            this_1.membrosCelula[i].dataEnvioQuestionario =
                                                questionario[0].dataEnvioQuestionario;
                                            this_1.membrosCelula[i].missaoAtualId =
                                                questionario[0].pessoamissaoAtualId;
                                            this_1.membrosCelula[i].questionarioNome = this_1.buscarNomeQuestionario(this_1.questionarioFormando.questionarioId);
                                            //this.membrosCelula[i].pedidoFormando = this.buscarNomePedido(questionario[0].indicacaoFC);
                                            this_1.membrosCelula[i].indicacaoFCNome = this_1.buscarNomePedido(questionario[0].indicacaoFC);
                                            this_1.membrosCelula[i].justificativaFC =
                                                questionario[0].justificativaFC;
                                            this_1.membrosCelula[i].indicacaoCD = questionario[0].indicacaoCD;
                                            this_1.membrosCelula[i].indicacaoCDNome = this_1.buscarNomePedido(questionario[0].indicacaoCD);
                                            this_1.membrosCelula[i].justificativaCD =
                                                questionario[0].justificativaCD;
                                        }
                                        if (this_1.membrosCelula[i].statusQuestionario == "NAOINICIADO" ||
                                            this_1.membrosCelula[i].statusQuestionario == undefined ||
                                            this_1.membrosCelula[i].statusQuestionario == null) {
                                            this_1.membrosCelula[i].statusQuestionario = "Não Iniciado";
                                        }
                                        if (this_1.membrosCelula[i].statusParecerFC == "NAOINICIADO" ||
                                            this_1.membrosCelula[i].statusParecerFC == undefined ||
                                            this_1.membrosCelula[i].statusParecerFC == null) {
                                            this_1.membrosCelula[i].statusParecerFC = "Não Iniciado";
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        i = 0;
                        _b.label = 3;
                    case 3:
                        if (!(i < this.membrosCelula.length)) return [3 /*break*/, 6];
                        return [5 /*yield**/, _loop_1(i)];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5:
                        i++;
                        return [3 /*break*/, 3];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_1 = _b.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComissaoComponent.prototype.buscarNomePedido = function (idPedido) {
        var nomePedido = "";
        if (idPedido) {
            switch (idPedido) {
                case 1:
                    nomePedido = "Mudança de Nível";
                    break;
                case 2:
                    nomePedido = "Permanecer no Nível";
                    break;
                case 3:
                    nomePedido = "Realizar Primeiras Promessas";
                    break;
                case 4:
                    nomePedido = "Realizar Promessas Definitivas";
                    break;
                case 5:
                    nomePedido = "Desligamento CAL";
                    break;
                case 6:
                    nomePedido = "Outro";
                    break;
                case 7:
                    nomePedido = "Permanecer no P1<";
                    break;
                case 8:
                    nomePedido = "Permanecer no P2";
                    break;
                case 9:
                    nomePedido = "Ingressar no P2";
                    break;
                case 10:
                    nomePedido = "Ingressar no D1";
                    break;
                case 11:
                    nomePedido = "Permanecer no D1";
                    break;
                case 12:
                    nomePedido = "Ingressar no D2";
                    break;
                case 13:
                    nomePedido = "Permanecer no D2";
                    break;
                case 14:
                    nomePedido = "Renovar as Promessas";
                    break;
                default:
                    nomePedido = null;
                    break;
            }
        }
        return nomePedido;
    };
    ParecerCelulaComissaoComponent.prototype.buscarNomeQuestionario = function (idQuestionario) {
        var nomeQuestionario = "";
        if (idQuestionario) {
            switch (idQuestionario) {
                case 4:
                    nomeQuestionario = "Pedido para as Primeiras Promessas - CA";
                    break;
                case 5:
                    nomeQuestionario = "Pedido para as Promessas Definitivas - CA";
                    break;
                case 6:
                    nomeQuestionario = "Postulantes/Ingresso no Discipulado - CA";
                    break;
                case 7:
                    nomeQuestionario = "Discipulos de Primeiro Ano - CA";
                    break;
                case 8:
                    nomeQuestionario = "Renovação das Promessas - CA";
                    break;
                default:
                    nomeQuestionario = null;
                    break;
            }
        }
        return nomeQuestionario;
    };
    ParecerCelulaComissaoComponent.prototype.carregarQuestionarioMembro = function (idMembros) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorMembrosId(idMembros)];
                    case 1:
                        response = _a.sent();
                        this.questionariosMembros = response;
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        console.log(e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComissaoComponent.prototype.carregarArquivoPDF = function (arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blobResponse, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.arquivoService
                            .obterDetalhesArquivoPDF(arquivoPDFNome)
                            .toPromise()];
                    case 1:
                        blobResponse = _a.sent();
                        url = URL.createObjectURL(blobResponse);
                        this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComissaoComponent.prototype.cadastrarConsideracaoComissao = function (membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.atualizarQuestionarioFormando(membro)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarMembrosCelula()];
                    case 2:
                        _a.sent();
                        this.swtAlert2Service.successAlert("Parecer de consideração da Comissão de Discernimento atualizado com sucesso!");
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComissaoComponent.prototype.atualizarQuestionarioFormando = function (membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var responseQuestionario, response, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorId(membro.idQuestionarioFormando)];
                    case 1:
                        responseQuestionario = _a.sent();
                        this.questionarioFormando = responseQuestionario;
                        if (!(this.questionarioFormando.id != null)) return [3 /*break*/, 3];
                        this.questionarioFormando.indicacaoCD = membro.indicacaoCD;
                        this.questionarioFormando.justificativaCD = membro.justificativaCD;
                        return [4 /*yield*/, this.questionarioFormandoService.atualizar(this.questionarioFormando.id, this.questionarioFormando)];
                    case 2:
                        response = _a.sent();
                        this.questionarioFormando = response;
                        this.swtAlert2Service.successAlert(response.message);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComissaoComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerCelulaComissaoComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.carregarMembrosCelula();
    };
    ParecerCelulaComissaoComponent.prototype.truncateText = function (text, limit) {
        if (limit === void 0) { limit = 50; }
        if (!text) {
            return '';
        }
        return text.length > limit ? text.substring(0, limit) + '...' : text;
    };
    return ParecerCelulaComissaoComponent;
}());
export { ParecerCelulaComissaoComponent };
