import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { MissaoConfiguracaoService, } from "../service/configuracao-questionario.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
var ParecerFormadorCasaComunitariaComponent = /** @class */ (function () {
    function ParecerFormadorCasaComunitariaComponent(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, casaComunitariaService, membroService, elementoGrupoDominioService, formadorAssistenteService, formadorComunitarioService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, caminhoFormativoService, arquivoService, sanitizer, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.casaComunitariaService = casaComunitariaService;
        this.membroService = membroService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.formadorAssistenteService = formadorAssistenteService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.arquivoService = arquivoService;
        this.sanitizer = sanitizer;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.unsubscribe$ = new Subject();
        this.totalDePaginas = 1;
        this.idsPPs = [25];
        this.idsPDs = [30, 31, 108, 204, 205];
        this.idsD1s = [24];
        this.idsPostulantes = [22, 23];
        this.idsRenovacao = [26, 27, 28, 29, 30, 31, 108, 204];
        this.selectedFileCarta = null;
        this.selectedFileArquivo = null;
        this.errorMessage = "";
        this.isFormValidUpload = false;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCasaComunitaria = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ParecerFormadorCasaComunitariaComponent.prototype.ngOnInit = function () {
        this.initializer();
        this.hasCasaComunitariaAssociada = false;
        this.buscaMembros = {
            casaComunitariaId: 0,
            pageNumber: 0,
            quantityOfElements: 0,
        };
        this.buscaQuestionarioFormando = {
            questionarioId: null,
            casaCasaComunitariaId: null,
            pessoaId: null,
            pageNumber: 0,
            quantityOfElements: 10,
        };
    };
    ParecerFormadorCasaComunitariaComponent.prototype.initializer = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        //this.questionarioId = Number(this.route.snapshot.paramMap.get("id"));
                        this.carregarNivelFormacao();
                        return [4 /*yield*/, this.carregarUsuario()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.carregarPessoa()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.carregarFormadorComunitario()];
                    case 3:
                        _a.sent();
                        if (!(this.formadorComunitario != undefined &&
                            this.formadorComunitario.casaCelulaId != null)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.carregarCasaComunitaria(this.formadorComunitario.casaCelulaId)];
                    case 4:
                        _a.sent();
                        this.buscaMembros = {
                            casaComunitariaId: this.casaComunitaria.id,
                            pageNumber: 0,
                            quantityOfElements: 4,
                        };
                        this.buscaQuestionarioFormando = {
                            questionarioId: this.questionarioId,
                            casaCasaComunitariaId: this.casaComunitaria.id,
                            pessoaId: null,
                        };
                        return [4 /*yield*/, this.carregarMembrosCasaComunitaria()];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 11];
                    case 6: return [4 /*yield*/, this.carregarFormadorAssistente()];
                    case 7:
                        _a.sent();
                        if (!(this.formadorAssistente != undefined &&
                            this.formadorAssistente.casaCelulaId != null)) return [3 /*break*/, 10];
                        return [4 /*yield*/, this.carregarCasaComunitaria(this.formadorAssistente.casaCelulaId)];
                    case 8:
                        _a.sent();
                        this.buscaMembros = {
                            casaComunitariaId: this.casaComunitaria.id,
                            pageNumber: 0,
                            quantityOfElements: 4,
                        };
                        this.buscaQuestionarioFormando = {
                            questionarioId: this.questionarioId,
                            casaCasaComunitariaId: this.casaComunitaria.id,
                            pessoaId: null,
                        };
                        return [4 /*yield*/, this.carregarMembrosCasaComunitaria()];
                    case 9:
                        _a.sent();
                        return [3 /*break*/, 11];
                    case 10:
                        this.hasCasaComunitariaAssociada = false;
                        _a.label = 11;
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    /*carregarQuestionarioConfiguracao(questionarioId: number) {
      const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
      this.missaoConfiguracaoService
        .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
          missaoId,
          questionarioId
        )
        .subscribe(
          (questionarioConfiguracao) =>
            (this.questionarioConfiguracao = questionarioConfiguracao)
        );
    }*/
    ParecerFormadorCasaComunitariaComponent.prototype.carregarUsuario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.usuarioService.getCurrentUser()];
                    case 1:
                        response = _a.sent();
                        this.usuario = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.carregarPessoa = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.pessoaService.buscaPorUsuarioId(this.usuario.id)];
                    case 1:
                        response = _a.sent();
                        this.pessoa = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.carregarFormadorComunitario = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        console.log("Carregar FC");
                        return [4 /*yield*/, this.formadorComunitarioService.buscarCasaComunitariaPorPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        this.formadorComunitario = response;
                        if (!(this.formadorComunitario &&
                            this.formadorComunitario.pessoaImagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.formadorComunitario.pessoaImagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formadorComunitario);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        e_3 = _a.sent();
                        console.log(e_3);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.carregarFormadorAssistente = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, blogImage, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.formadorAssistenteService.buscarCasaComunitariaPorPessoaId(this.pessoa.id)];
                    case 1:
                        response = _a.sent();
                        this.formadorAssistente = response;
                        if (!(this.formadorAssistente &&
                            this.formadorAssistente.pessoaImagemId != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getPessoaImagem(this.formadorAssistente.pessoaImagemId)];
                    case 2:
                        blogImage = _a.sent();
                        this.imagemService.createImage(blogImage, this.formadorAssistente);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        e_4 = _a.sent();
                        console.log(e_4);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.carregarNivelFormacao = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = {
                            grupoNome: "NIVEL_FORMACAO",
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request)];
                    case 2:
                        response = _a.sent();
                        this.niveisFormacao = response.entity;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_1.error.errors);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.carregarCasaComunitaria = function (casacomunitariaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.casaComunitariaService.find(casacomunitariaId)];
                    case 1:
                        response = _a.sent();
                        this.casaComunitaria = response.entity;
                        return [3 /*break*/, 3];
                    case 2:
                        e_5 = _a.sent();
                        console.log(e_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.carregarMembrosCasaComunitaria = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, idMembros, _loop_1, this_1, i, err_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        return [4 /*yield*/, this.membroService.listarTodosMembrosCasaComunitaria(this.casaComunitaria.id)];
                    case 1:
                        response = _a.sent();
                        this.membrosCasaComunitaria = response.content;
                        this.totalDePaginasMembros = response.totalPages;
                        this.buscaMembros.quantityOfElements = response.quantityOfElements;
                        idMembros = this.membrosCasaComunitaria.map(function (membro) { return membro.id; });
                        return [4 /*yield*/, this.carregarQuestionarioCasaComunitaria(idMembros)];
                    case 2:
                        _a.sent();
                        _loop_1 = function (i) {
                            var response_1, pessoa, blogImage, nivelFormativoMembro, questionario;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this_1.pessoaService.buscaPorId(this_1.membrosCasaComunitaria[i].pessoaId)];
                                    case 1:
                                        response_1 = _a.sent();
                                        pessoa = response_1.entity;
                                        this_1.membrosCasaComunitaria[i].pessoaNome = pessoa.nome + " " + pessoa.sobrenome;
                                        this_1.membrosCasaComunitaria[i].pessoaImagemId = pessoa.imagemId;
                                        this_1.membrosCasaComunitaria[i].vinculoMissao = pessoa.vinculoMissao;
                                        if (!(this_1.membrosCasaComunitaria[i].pessoaImagemId != null)) return [3 /*break*/, 3];
                                        return [4 /*yield*/, this_1.getPessoaImagem(this_1.membrosCasaComunitaria[i].pessoaImagemId)];
                                    case 2:
                                        blogImage = _a.sent();
                                        this_1.imagemService.createImage(blogImage, this_1.membrosCasaComunitaria[i]);
                                        _a.label = 3;
                                    case 3:
                                        nivelFormativoMembro = this_1.niveisFormacao.find(function (obj) { return obj.id === _this.membrosCasaComunitaria[i].nivelFormativoId; });
                                        this_1.membrosCasaComunitaria[i].nivelFormativoNome = nivelFormativoMembro
                                            ? nivelFormativoMembro.nome
                                            : null;
                                        questionario = this_1.questionariosCasaComunitaria.filter(function (questionarioFormando) {
                                            return questionarioFormando.id === _this.membrosCasaComunitaria[i].id;
                                        });
                                        if (questionario.length > 0) {
                                            this_1.membrosCasaComunitaria[i].statusQuestionario =
                                                questionario[0].status_questionario;
                                            this_1.membrosCasaComunitaria[i].questionarioFormandoId =
                                                questionario[0].questionarioFormandoId;
                                            this_1.membrosCasaComunitaria[i].questionarioId = questionario[0].questionarioId;
                                            this_1.membrosCasaComunitaria[i].questionarioNome = this_1.buscarNomeQuestionario(this_1.membrosCasaComunitaria[i].questionarioId);
                                            this_1.membrosCasaComunitaria[i].statusParecerFC =
                                                questionario[0].status_parecer_fc;
                                            this_1.membrosCasaComunitaria[i].idParecerFC = questionario[0].parecerIdFC;
                                            this_1.membrosCasaComunitaria[i].acompanhadoPor = questionario[0].acompanhadoPor;
                                            this_1.membrosCasaComunitaria[i].acompanhadoPorNome =
                                                questionario[0].acompanhadoPorNome;
                                            this_1.membrosCasaComunitaria[i].arquivo = questionario[0].arquivo;
                                            this_1.membrosCasaComunitaria[i].dataEnvioParecerFC =
                                                questionario[0].dataEnvioParecerFC;
                                            this_1.membrosCasaComunitaria[i].dataEnvioQuestionario =
                                                questionario[0].dataEnvioQuestionario;
                                            this_1.membrosCasaComunitaria[i].missaoAtualId = questionario[0].missaoAtualId;
                                            this_1.membrosCasaComunitaria[i].carta = questionario[0].carta;
                                            this_1.membrosCasaComunitaria[i].indicacaoCD = questionario[0].indicacaoCD;
                                            this_1.membrosCasaComunitaria[i].justificativaCD =
                                                questionario[0].justificativaCD;
                                            this_1.membrosCasaComunitaria[i].indicacaoCL = questionario[0].indicacaoCL;
                                            this_1.membrosCasaComunitaria[i].justificativaCL =
                                                questionario[0].justificativaCL;
                                            this_1.membrosCasaComunitaria[i].consideracaoCL = questionario[0].consideracaoCL;
                                            this_1.membrosCasaComunitaria[i].indicacaoCR = questionario[0].indicacaoCR;
                                            this_1.membrosCasaComunitaria[i].justificativaCR =
                                                questionario[0].justificativaCR;
                                            this_1.membrosCasaComunitaria[i].justificativaGG =
                                                questionario[0].justificativaGG;
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        i = 0;
                        _a.label = 3;
                    case 3:
                        if (!(i < this.membrosCasaComunitaria.length)) return [3 /*break*/, 6];
                        return [5 /*yield**/, _loop_1(i)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        i++;
                        return [3 /*break*/, 3];
                    case 6:
                        if (this.membrosCasaComunitaria.length > 0) {
                            this.membrosCasaComunitaria = this.membrosCasaComunitaria.sort(function (a, b) {
                                return a.pessoaNome.localeCompare(b.pessoaNome);
                            });
                        }
                        return [3 /*break*/, 8];
                    case 7:
                        err_2 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_2.error.errors);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.carregarQuestionarioCasaComunitaria = function (idMembros) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, e_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorMembrosId(idMembros)];
                    case 1:
                        response = _a.sent();
                        this.questionariosCasaComunitaria = response;
                        return [3 /*break*/, 3];
                    case 2:
                        e_6 = _a.sent();
                        console.log(e_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.consultarCaminhoFormativo = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, response_2, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.caminhoFormativoService.porPessoaId(formando.pessoaId)];
                    case 1:
                        response = _a.sent();
                        if (!(response != null && response != undefined)) return [3 /*break*/, 2];
                        this.caminhoFormativo = response;
                        return [3 /*break*/, 4];
                    case 2:
                        this.caminhoFormativo = {};
                        this.caminhoFormativo.pessoaId = formando.pessoaId;
                        this.caminhoFormativo.missao_atual_id = formando.missaoAtualId;
                        this.caminhoFormativo.ano_formativo_id = 2;
                        this.caminhoFormativo.nome_formador_comunitario =
                            this.pessoa.nome + " " + this.pessoa.sobrenome;
                        return [4 /*yield*/, this.caminhoFormativoService.cadastrar(this.caminhoFormativo)];
                    case 3:
                        response_2 = _a.sent();
                        this.caminhoFormativo = response_2;
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_3 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_3.error.errors);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.cadastrarVinculoQuestionarioCAL = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var responsePorPessoa, response, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.consultarCaminhoFormativo(formando)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.questionarioFormandoService.buscarPorPessoaIdEQuestionarioId(formando.pessoaId, formando.questionarioId)];
                    case 2:
                        responsePorPessoa = _a.sent();
                        if (!(responsePorPessoa != undefined &&
                            responsePorPessoa != null &&
                            responsePorPessoa.questionarioId == formando.questionarioId)) return [3 /*break*/, 3];
                        this.questionarioFormando = responsePorPessoa;
                        this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                        return [3 /*break*/, 5];
                    case 3:
                        this.questionarioFormando.anoFormativoId = 2;
                        this.questionarioFormando.questionarioId = formando.questionarioId;
                        this.questionarioFormando.pessoaId = formando.pessoaId;
                        return [4 /*yield*/, this.questionarioFormandoService.cadastrar(this.questionarioFormando)];
                    case 4:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.questionarioFormando = response;
                            this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                            formando.questionarioFormandoId = this.questionarioFormando.id;
                        }
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_4 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_4.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.cadastrarVinculoParecerCAL = function (questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                        this.parecerResponsavel.parecer_id = 3;
                        this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
                        this.parecerResponsavel.tipo = "FC";
                        return [4 /*yield*/, this.parecerResponsavelService.cadastrar(this.parecerResponsavel)];
                    case 1:
                        response = _a.sent();
                        if (response != undefined && response != null) {
                            this.parecerResponsavel = response;
                            this.router.navigate([
                                "/secured/parecer-cal-answer-default" +
                                    "/" +
                                    this.parecerResponsavel.id,
                            ]);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_5 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_5.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.getPessoaImagem = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.imagemService.getPessoaPhoto(id)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.body];
                    case 2:
                        err_6 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_6.error.errors);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.buscarNomeQuestionario = function (idQuestionario) {
        var nomeQuestionario = "";
        if (idQuestionario) {
            switch (idQuestionario) {
                case 4:
                    nomeQuestionario = "Pedido para as Primeiras Promessas - CA";
                    break;
                case 5:
                    nomeQuestionario = "Pedido para as Promessas Definitivas - CA";
                    break;
                case 6:
                    nomeQuestionario = "Postulantes/Ingresso no Discipulado - CA";
                    break;
                case 7:
                    nomeQuestionario = "Discipulos de Primeiro Ano - CA";
                    break;
                case 8:
                    nomeQuestionario = "Renovação das Promessas - CA";
                    break;
                default:
                    nomeQuestionario = null;
                    break;
            }
        }
        return nomeQuestionario;
    };
    ParecerFormadorCasaComunitariaComponent.prototype.consultarVinculoQuestionarioCAL = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log(formando);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!(formando.questionarioFormandoId != undefined &&
                            formando.questionarioFormandoId != null)) return [3 /*break*/, 2];
                        if (formando.idParecerFC != undefined && formando.idParecerFC != null) {
                            this.router.navigate([
                                "/secured/parecer-cal-answer-default" + "/" + formando.idParecerFC,
                            ]);
                        }
                        else {
                            this.cadastrarVinculoParecerCAL(formando.questionarioFormandoId);
                        }
                        return [3 /*break*/, 5];
                    case 2:
                        if (!(formando.questionarioFormandoId == undefined ||
                            formando.questionarioFormandoId == null)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.cadastrarVinculoQuestionarioCAL(formando)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.cadastrarVinculoParecerCAL(formando.questionarioFormandoId)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_7 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_7.error.errors);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.carregarArquivoPDF = function (arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blobResponse, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.arquivoService
                            .obterDetalhesArquivoPDF(arquivoPDFNome)
                            .toPromise()];
                    case 1:
                        blobResponse = _a.sent();
                        url = URL.createObjectURL(blobResponse);
                        this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.configModalUploadQuestionario = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.questionarioFormandoSelectedNome = formando.pessoaNome;
                        this.questionarioSelectedId = formando.questionarioId;
                        if (!(formando.questionarioFormandoId != undefined &&
                            formando.questionarioFormandoId != null)) return [3 /*break*/, 1];
                        this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.cadastrarVinculoQuestionarioCAL(formando)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.onFileSelected = function (event) {
        var file = event.target.files[0];
        // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
        if (file &&
            file.type === "application/pdf" &&
            file.size <= 3 * 1024 * 1024) {
            this.selectedFileArquivo = file;
            this.errorMessage = "";
        }
        else {
            this.selectedFileArquivo = null;
            this.errorMessage = "O arquivo deve ser um PDF com no máximo 3 MB.";
        }
        this.checkFormUploadValidity();
    };
    ParecerFormadorCasaComunitariaComponent.prototype.onUpload = function () {
        var _this = this;
        if (this.selectedFileArquivo && this.pedidoFormandoId) {
            this.questionarioFormandoService
                .uploadQuestionario(this.questionarioFormandoSelectedId, this.pedidoFormandoId, this.selectedFileArquivo)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                next: function (data) {
                    _this.swtAlert2Service.successAlert(data);
                    _this.carregarMembrosCasaComunitaria();
                    $("#modal-upload-questionario").modal("hide");
                },
                error: function (error) {
                    _this.swtAlert2Service.errorAlert("Erro ao realizar o upload do arquivo.");
                },
                complete: function () {
                    _this.unsubscribe$.next();
                },
            });
            this.pedidoFormandoId = null;
            this.questionarioFormandoSelectedId = null;
            this.questionarioFormandoSelectedNome = null;
        }
        else {
            if (!this.pedidoFormandoId) {
                this.errorMessage =
                    "O campo 'Qual Pedido o seu do Formando está fazendo' deve ser informado.";
            }
            else {
                this.errorMessage =
                    "Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb";
            }
        }
    };
    ParecerFormadorCasaComunitariaComponent.prototype.onTipoPedidoChange = function () {
        this.checkFormUploadValidity();
    };
    // Verifica se tanto o arquivo quanto o tipo de pedido foram selecionados
    ParecerFormadorCasaComunitariaComponent.prototype.checkFormUploadValidity = function () {
        this.isFormValidUpload =
            !!this.pedidoFormandoId && !!this.selectedFileArquivo;
    };
    /**
     * CARTA
     */
    ParecerFormadorCasaComunitariaComponent.prototype.configModalUploadCarta = function (formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.questionarioFormandoSelectedNome = formando.pessoaNome;
                        if (!(formando.questionarioFormandoId != undefined &&
                            formando.questionarioFormandoId != null)) return [3 /*break*/, 1];
                        this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.cadastrarVinculoQuestionarioCAL(formando)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.carregarCartaPDF = function (arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blobResponse, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.arquivoService
                            .obterDetalhesArquivoPDF(arquivoPDFNome)
                            .toPromise()];
                    case 1:
                        blobResponse = _a.sent();
                        url = URL.createObjectURL(blobResponse);
                        this.sanitizedPdfCartaUrl =
                            this.sanitizer.bypassSecurityTrustResourceUrl(url);
                        return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.onCartaFileSelected = function (event) {
        var file = event.target.files[0];
        // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
        if (file &&
            file.type === "application/pdf" &&
            file.size <= 3 * 1024 * 1024) {
            this.selectedFileCarta = file;
            this.errorMessage = "";
        }
        else {
            this.selectedFileCarta = null;
            this.errorMessage = "O arquivo deve ser um PDF com no máximo 3 MB.";
        }
    };
    ParecerFormadorCasaComunitariaComponent.prototype.onCartaUpload = function () {
        var _this = this;
        if (this.selectedFileCarta) {
            this.questionarioFormandoService
                .uploadCarta(this.questionarioFormandoSelectedId, this.selectedFileCarta)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                next: function (data) {
                    _this.swtAlert2Service.successAlert(data);
                    _this.carregarMembrosCasaComunitaria();
                    $("#modal-upload-carta").modal("hide");
                },
                error: function (error) {
                    _this.swtAlert2Service.errorAlert("Erro ao realizar o upload do arquivo.");
                },
                complete: function () {
                    _this.unsubscribe$.next();
                },
            });
            this.questionarioFormandoSelectedId = null;
            this.questionarioFormandoSelectedNome = null;
        }
        else {
            this.errorMessage =
                "Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb";
        }
    };
    ParecerFormadorCasaComunitariaComponent.prototype.removerArquivoQuestionario = function (questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm("Deseja remover esse questionário?")) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.questionarioFormandoService
                                .removerArquivoQuestionario(questionarioFormandoId)
                                .toPromise()];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, this.carregarMembrosCasaComunitaria()];
                    case 3:
                        _a.sent();
                        this.swtAlert2Service.successAlert(response);
                        return [3 /*break*/, 5];
                    case 4:
                        err_8 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_8.error);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.removerArquivoCarta = function (questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm("Deseja remover essa carta?")) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.questionarioFormandoService
                                .removerArquivoCarta(questionarioFormandoId)
                                .toPromise()];
                    case 2:
                        response = _a.sent();
                        return [4 /*yield*/, this.carregarMembrosCasaComunitaria()];
                    case 3:
                        _a.sent();
                        this.swtAlert2Service.successAlert(response);
                        return [3 /*break*/, 5];
                    case 4:
                        err_9 = _a.sent();
                        this.swtAlert2Service.errorAlert(err_9.error);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ParecerFormadorCasaComunitariaComponent.prototype.bloquearRemocaoDeArquivos = function (membroComunidade) {
        return (membroComunidade.indicacaoCL ||
            membroComunidade.justificativaCL ||
            membroComunidade.consideracaoCL ||
            membroComunidade.indicacaoCD ||
            membroComunidade.justificativaCD ||
            membroComunidade.indicacaoCR ||
            membroComunidade.justificativaCR ||
            membroComunidade.justificativaGG);
    };
    ParecerFormadorCasaComunitariaComponent.prototype.cliqueNoBotaoListener = function (botao) {
        this.buscaMembros.pageNumber = botao.numero - 1;
        this.carregarMembrosCasaComunitaria();
    };
    return ParecerFormadorCasaComunitariaComponent;
}());
export { ParecerFormadorCasaComunitariaComponent };
