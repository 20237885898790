import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { SecuredModule } from "../../layout/secured.module";
import { IgxTabsModule } from "igniteui-angular";
import { ImageCropperModule } from "ngx-image-cropper";
import { ParecerComponent } from "./parecer.component";
import { DadoFormandoCVService } from "./service/dadoformandocv.service";
import { CaminhoFormativoService } from "./service/caminhoformativo.service";
import { DadoFormandoCVBuilder } from "src/app/builder/dadoformandocv.builder";
import { CaminhoFormativoBuilder } from "src/app/builder/caminhoformativo.builder";
import { ParecerRoutingModule } from "./parecer-routing.module";
import { ParecerD2FCAnswerComponent } from "./formador_comunitario/parecerd2-fc-answer.component";
import { ParecerResponsavelService } from "./service/parecerresponsavel.service";
import { ParecerResponsavelBuilder } from "src/app/builder/parecerresponsavel.builder";
import { ParecerService } from "./service/parecer.service";
import { RespostaParecerBuilder } from "src/app/builder/respostaparecer.builder";
import { RespostaParecerService } from "./service/respostaparecer.service";
import { ParecerAgradecimentoComponent } from "./formador_comunitario/parecer-agradecimento.component";
import { ListagemPessoaInfoModalModule } from "../pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.module";
import { PessoaService } from "../pessoa/service/pessoa.service";
import { ImagemService } from "../../service/imagem.service";
import { ParecerCelulaComponent } from "./parecer.celula.component";
import { ParecerCelulaAutoridadeComponent } from "./parecer.celula.autoridade.component";
import { ParecerCoordenadorColegiadoCelulaComponent } from "./coordenador_colegiado/parecer.cc.celula.component";
import { CoordenadorColegiadoService } from "../../service/coordenadorcolegiado.service";
import { ParecerCALFCAnswerComponent } from "./formador_comunitario/parecer-cal-fc-answer.component";
import { DadoFormandoCABuilder } from "src/app/builder/dadoformandoca.builder";
import { DadoFormandoCAService } from "./service/dadoformandoca.service";
import { ParecerCoordenadorColegiadoViewComponent } from "./coordenador_colegiado/parecer-cc-view.component";
import { DiscernimentoService } from "../../service/discernimento.service";
import { ParecerCALFCAnswerDefaultComponent } from "./formador_comunitario/parecer-cal-fc-answer-default.component";
import { ParecerCALAnswerDefaultViewComponent } from "./parecer-cal-answer-default-view.component";
import { ParecerCelulaComissaoComponent } from "./parecer.celula.comissao.component";
import { ParecerCALAnswerDefaultViewPublicComponent } from "src/app/public/parecer/parecer-cal-answer-default-view-public.component";
import { ParecerCasaComunitariaAutoridadeComponent } from "./parecer.casacomunitaria.autoridade.component";

@NgModule({
  declarations: [
    ParecerComponent,
    ParecerD2FCAnswerComponent,
    ParecerCALFCAnswerComponent,
    ParecerCALFCAnswerDefaultComponent,
    ParecerAgradecimentoComponent,
    ParecerCelulaComponent,
    ParecerCelulaAutoridadeComponent,
    ParecerCoordenadorColegiadoCelulaComponent,
    ParecerCoordenadorColegiadoViewComponent,
    ParecerCALAnswerDefaultViewComponent,
    ParecerCelulaComissaoComponent,
    ParecerCALAnswerDefaultViewPublicComponent,
    ParecerCasaComunitariaAutoridadeComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    ParecerRoutingModule,
    SecuredModule,
    IgxTabsModule,
    ImageCropperModule,
    ListagemPessoaInfoModalModule,
  ],
  providers: [
    PessoaService,
    ImagemService,
    DadoFormandoCVService,
    CaminhoFormativoService,
    ParecerService,
    ParecerResponsavelService,
    RespostaParecerService,
    DadoFormandoCVBuilder,
    DadoFormandoCABuilder,
    CaminhoFormativoBuilder,
    ParecerResponsavelBuilder,
    RespostaParecerBuilder,
    CoordenadorColegiadoService,
    DadoFormandoCAService,
    DiscernimentoService,
  ],
})
export class ParecerModule {}
