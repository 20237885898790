<aside class="main-sidebar sidebar-light-orange elevation-4">
    <!-- Brand Logo -->
    <a [routerLink]="'/secured/perfil'" class="brand-link navbar-orange">
        <img src="assets/images/logo.png" alt="Missionário" class="brand-image img-circle elevation-3"
            style="opacity: .8">
        <span class="brand-text font-weight-strong">MISSIONÁRIO</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar scrollbar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
                <img class="img-circle elevation-2"
                    [src]="pessoaAtual?.thumbnail == null ? 'assets/images/ppl.png' : pessoaAtual?.thumbnail"
                    alt="User Avatar">
            </div>
            <div class="info">
                <a [routerLink]="'/secured/perfil'" class="d-block">
                    <small>{{pessoaAtual != null ? pessoaAtual.nome : ''}}</small>
                </a>
            </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-header">
                    <strong>MENU</strong>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlperfil.isActive || rldadosvocacionais.isActive }">
                        <i class="nav-icon fas fa-id-card"></i>
                        <p i18n>
                            Pessoa
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/perfil'" routerLinkActive="active" #rlperfil="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Perfil</p>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/dadosvocacionais'" routerLinkActive="active"
                                #rldadosvocacionais="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Dados Vocacionais</p>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/saude'" routerLinkActive="active" #rlsaude="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Saúde</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <!--li class="nav-item has-treeview" routerLinkActive="menu-open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlccgfm.isActive }">
                        <i class="nav-icon fas fa-home"></i>
                        <p i18n>
                            Casa comunitaria
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/local/casacomunitaria/gerenciamento" routerLinkActive="active" #rlccgfm="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Minha Casa Comunitária</p>
                            </a>
                        </li-->
                <!-- <li class="nav-item ">
                            <a routerLink="/secured/local/casacomunitaria/calendario" routerLinkActive="active" #rlccc="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Calendário</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured//local/casacomunitaria/recicladem" routerLinkActive="active" #rlccl="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Reciclagem</p>
                            </a>
                        </li>                         -->
                <!--/ul>
                </li-->
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link"
                        [ngClass]="{'active': cl_listar.isActive  }">
                        <i class="nav-icon fas fa-home"></i>
                        <p i18n>
                            Célula
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/celula/fc/gerenciamento" routerLinkActive="active"
                                #cl_minha_celula="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Minha Célula</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/secured/celula/parecer/fc" routerLinkActive="active"
                                #cl_discernimento="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Discernimentos</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/cc/celulas" routerLinkActive="active"
                                #cl_listar="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                        <!-- <li class="nav-item ">
                            <a routerLink="/secured/local/casacomunitaria/calendario" routerLinkActive="active" #rlccc="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Calendário</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured//local/casacomunitaria/recicladem" routerLinkActive="active" #rlccl="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Reciclagem</p>
                            </a>
                        </li>                         -->
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link"
                        [ngClass]="{'active': cs_listar.isActive  }">
                        <i class="nav-icon fas fa-home"></i>
                        <p i18n>
                            Casa Comunitária
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <!--li class="nav-item">
                            <a routerLink="/secured/celula/fc/gerenciamento" routerLinkActive="active"
                                #cs_minha_celula="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Minha Casa Comunitária</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/secured/celula/parecer/fc" routerLinkActive="active"
                                #cl_discernimento="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Discernimentos</p>
                            </a>
                        </li-->
                        <li class="nav-item ">
                            <a routerLink="/secured/cc/casacomunitaria" routerLinkActive="active"
                                #cs_listar="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                        <!-- <li class="nav-item ">
                            <a routerLink="/secured/local/casacomunitaria/calendario" routerLinkActive="active" #rlccc="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Calendário</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured//local/casacomunitaria/recicladem" routerLinkActive="active" #rlccl="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Reciclagem</p>
                            </a>
                        </li>                         -->
                    </ul>
                </li>
                <!--li class="nav-item">
                    <a class="nav-link" routerLink="/secured/questionarios">
                        <i class="nav-icon fa fa-list"></i>
                        <p i18n>
                            Questionário
                        </p>
                    </a>
                </li-->
                <!--li class="nav-item">
                    <a class="nav-link" routerLink="/secured/parecer">
                        <i class="nav-icon fa fa-list"></i>
                        <p i18n>
                            Parecer
                        </p>
                    </a>
                </li-->
                <!--li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link"
                        [ngClass]="{'active': menuMeuColegiado.isActive || menuDiscernimentos.isActive }">
                        <i class="nav-icon fas fa-home"></i>
                        <p i18n>
                            Colegiado
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/celula/cc/meucolegiado" routerLinkActive="active"
                                #menuMeuColegiado="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Meu Colegiado</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/secured/celula/cc/discernimentos" routerLinkActive="active"
                                #menuDiscernimentos="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Discernimentos</p>
                            </a>
                        </li>
                    </ul>
                </li-->
                <li class="nav-header">
                    <strong>CONFIGURAÇÃO</strong>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlmu.isActive }">
                        <i class="nav-icon fas fa-user"></i>
                        <p i18n>
                            Usuário
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/meuusuario" routerLinkActive="active" #rlmu="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Meu Usuário</p>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>